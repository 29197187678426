/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1600px) {

    .dexon_menu>ul>li>a {
        margin: 30px 6px !important;
        font-size: 15px !important;
    }

    a.logo_img img {
        width: 170px !important;
    }

    .header-button a {
        padding: 10px 30px;
        font-size: 14px;
    }

    .about-counter {
        right: 55px;
    }

    .tabs li a {
        padding: 20px 58px;
    }

    .team-list .owl-nav {
        bottom: 12%;
    }

    .teastimonial-single-box {
        padding: 0 35px 0;
    }

    h2.blog-title a {
        font-size: 20px;
    }


}


/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1199px) {

    a.logo_img img {
        width: 90%;
    }

    a.main_sticky img {
        width: 100%;
    }


    .dexon_menu>ul>li>a {
        font-size: 14px;
        margin: 29px 5px;
    }

    .header-button {
        padding-left: 5px;
    }

    .header-button a {
        font-size: 13px;
        padding: 9px 20px;
    }

    /*hero section
    ===================*/

    .hero-section {
        height: 790px;
    }

   .hero-content h1 {
        font-size: 70px;
        font-weight: 600;
    }

    .hero-content h2 {
        font-size: 68px;
        padding: 5px 0 20px 0px;
    }

    .hero-content h2:before {
        background: transparent;
    }

    .hero-content p {
        width: 50%;
    }

    .hero-all-shape {
        display: none;
    }

    /*style two*/

    .hero-content2 h1 {
        font-size: 50px;
        line-height: 50px;
    }

    .hero-thumb {
        padding-left: 0;
    }

    .hero-thumb img {
        width: 100%;
    }

    .hero-counter {
        padding-left: 12px;
    }

    .hero-counter.upper {
        padding-left: 25px;
    }

    .hero-counter.upper:before {
        background: transparent;
    }

    .hero-counter h2 {
        font-size: 30px;
    }

    .hero-counter span {
        padding: 0;
        font-size: 15px;
    }

    /*Feature section
    =========================*/

    .feature-single-box {
        padding: 45px 35px 28px;
    }

    .dream-shape {
        display: none;
    }

    .style-two .feature-single-box {
        padding: 40px 35px 28px;
    }

    .style-two .feature-content h2 {
        font-size: 22px;
    }

    /*Start Section title
    =========================*/

    .dexon-section-title h1 {
        font-size: 30px;
        line-height: 40px;
    }

    /*Start About Section
    =========================*/


    .about-right-thumb img {
        width: 100%;
    }

    .row.about-bg2 .counter-text span {
        padding: 0 0 0 8px;
        margin-left: 8px;
        font-size: 15px;
    }

    .about-thumb2 img {
        width: 100%;
    }

    .about-section .about-thumb {
        padding: 0 0 0 0px;
    }

    .about-section .about-thumb img {
        width: 100%;
    }

    .about-thumb img {
        width: 100%;
    }

    .about-left-thumb img {
        width: 100%;
    }

    .testimonial-single-box2 {
        margin-bottom: 0px;
    }

    .testimonial-single-box2 .testi-description p {
        line-height: 28px;
    }

    /*Service Section
    ========================*/

    .service-content {
        padding: 54px 20px 35px 30px;
    }

    .row.about-bg2 .counter-title h1 {
        font-size: 28px;
        margin: 0;
    }

    h3.service-title {
        font-size: 19px;
    }

    p.service-desc {
        font-size: 14px;
    }

    a.dexon-button {
        font-size: 14px;
    }

    .about-thumb2 {
        padding-left: 70px;
    }

    .serivce-thumb img {
        width: 98%;
    }

    .single-service-box2 .service-content {
        padding: 0;
    }



    /* Team  Section
    =================*/

    h3.team-title {
        font-size: 18px;
    }

    .team-list .owl-nav {
        bottom: 13%;
        left: -25%;
    }


    /*Testi Section
    =====================*/

    .teastimonial-single-box {
        padding: 0 10px 0;
    }

    .testi-description p {
        padding: 40px 0 28px;
        font-size: 15px;
    }

    .company-rating ul li {
        font-size: 13px;
        letter-spacing: 2px;
    }

    /*start call to action 
    =======================*/

    .call-number h1 {
        font-size: 20px;
        padding: 18px 0 5px;
    }
    
    .call-text span {
        font-size: 15px;
    }

    /*Footer Section
    =====================*/

    h2.widget-title {
        font-size: 25px;
    }

    ul.footer-info li a {
        font-size: 14px;
    }

    .service-details-thumb {
        overflow: hidden;
    }
    .service-work-process-title h4 {
        font-size: 20px;
    }
    .service-work-process-box {
        padding: 40px 24px 20px;
    }

    /*inner page Counter
    ====================*/

    .contact-thumb img {
        width: 100%;
    }

    .contact-info {
        padding: 40px 0 40px;
    }

    .single-counter-box {
        text-align: center;
    }

    .counter-icon {
        float: inherit;
    }

    /*progess
    ===========*/

    .progress-single-box {
        width: 25%;
        bottom: 35%;
        left: 11%;
    }

    /*Service Detials
    =====================*/

    .service-details:before {
        width: 30%;
    }

    .widget-sidebar-box {
        padding: 30px 20px 30px;
    }

    ul.sidebar-menu li {
        padding: 13px 10px 13px;
    }

    ul.sidebar-menu li a {
        font-size: 14px;
    }

    ul.sidebar-menu li a i {
        font-size: 15px;
    }

    img.sidbr-icn {
        padding-right: 6px;
    }

    ul.sidebar-menu2 li {
        font-size: 13px;
    }

    h3.widget-title2 {
        font-size: 22px;
    }

    h5.sidebar-title:before {
        right: 135px;
        width: 65px;
    }

    h5.sidebar-title:after {
        left: 135px;
        width: 65px;
    }

    h5.sidebar-title2 {
        font-size: 22px;
    }

    .widget-button a {
        padding: 11px 32px;
        font-size: 14px;
    }

    .service-dtls-cont h3 {
        font-size: 26px;
        line-height: 37px;
    }

    .sevice-dtls-content {
        padding-left: 15px;
    }

    .sevice-dtls-content h4 {
        font-size: 16px;
    }

    .sevice-dtls-content p {
        font-size: 14px;
    }

    /*Pricing
    ==================*/

    span.pricing-icn {
        height: 70px;
        width: 70px;
        line-height: 70px;
    }

    span.pricing-icn img {
        width: 60%;
    }

    /*Project
    =================*/

    h3.case-study-title a {
        padding: 14px 45px 14px 25px;
        font-size: 16px;
    }

    h6.case-study-title {
        font-size: 14px;
    }

    .project-content {
        left: 22px;
    }
    .project-info {
        padding: 35px 37px 30px;
    }

    /*Shop Sectio
    ====================*/

    .shop-section:before {
        width: 30%;
    }

    .widget-check-box {
        padding: 20px 20px 1px;
    }

    .widget-check {
        font-size: 15px;
    }

    .range-wrapper-box {
        padding: 20px 20px 25px;
    }

    .product-categories-box {
        padding: 20px 18px 5px;
    }

    .product-thumb {
        padding-right: 10px;
    }

    .product-price span {
        font-size: 13px;
    }

    .product-icon-list ul li {
        font-size: 12px;
    }

    .products-title h6 {
        font-size: 13px;
    }

    .product-sale span {
        padding: 2px 16px;
        font-size: 12px;
    }

    .product-title h2 {
        font-size: 16px;
    }

    .form_box select {
        width: 40%;
    }

    p.description {
        font-size: 13px;
        line-height: 26px;
    }
     
    .style-three.tab {
        padding: 0px 25px 0px;
    } 

    .tabs_item img {
        width: 100%;
    } 

    .style-three .tabs li a {
        margin-right: 15px;
    } 

    .single-products-box:hover .product-thumb-icon a:nth-child(1) {
        left: 55px;
    }

    .single-products-box:hover .product-thumb-icon a:nth-child(2) {
        right: 55px;
    }

    /*Blog Section
    =====================*/

    .blogs-section:before {
        width: 30%;
    }
    .blogs-section .widget-sidebar-box {
        padding: 13px 20px 30px;
    }

    .rpost-thumb {
        margin-right: 15px;
    }

    .rpost-title h4 {
        margin: 0;
    }

    .rpost-content h4 a {
        font-size: 14px;
        line-height: 23px;
    }

    .upp.widget-sidebar-box {
        padding: 13px 20px 15px;
    }

    .tag-item a {
        font-size: 14px;
        padding: 6px 14px;
    }

    .blogs-section h2.blog-title a {
        font-size: 20px;
        font-weight: 500;
    }

    .blogs-section .blog-content {
        padding: 35px 20px 18px;
    }

    .style-two.blogs-section h2.blog-title a {
        font-size: 18px;
        font-weight: 600;
    }

    p.blog-desc {
        font-size: 14px;
    }

    ul.blog-author li {
        margin-right: 22px;
        font-size: 14px;
    }

    ul.blog-author li span:before {
        right: -13px;
    }

    .blog-quote p {
        font-size: 17px;
    }

    .blog-post-comment span.rights-reply {
        top: -92px;
    }

}


/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {

    .dexon_nav_manu {
        display: none;
    }

    .header-top-section {
        display: none;
    }

    
   /*hero section
    ===================*/

    .hero-section {
        height: 790px;
    }

   .hero-content h1 {
        font-size: 70px;
        font-weight: 600;
    }

    .hero-content h2 {
        font-size: 68px;
        padding: 5px 0 20px 0px;
    }

    .hero-content h2:before {
        background: transparent;
    }

    .hero-content p {
        width: 58%;
    }

    .hero-all-shape {
        display: none;
    }

    .hero2.hero-section {
        height: 1250px;
    }

    .hero-content2 h1 {
        font-size: 45px;
        line-height: 45px;
    }

    .hero-thumb {
        margin-top: 30px;
    }
    .hero-content-text-btn {
        margin-left: 0px;
    }
    /*Feature section
    =========================*/

    .dream-shape {
        display: none;
    }

    /*Start Section title
    =========================*/

    .dexon-section-title h1 {
        font-size: 30px;
        line-height: 40px;
    }

    .text-center.dexon-section-title p {
        width: 54%;
    }

    /*Start About Section
    =========================*/


    .about-thumb {
        margin-bottom: 40px;
    }

    .dream-shape4 {
        right: 1rem;
    }

    .row.about-bg2 .counter-title h1 {
        font-size: 40px;
    }

    .about-section .about-thumb {
        padding: 35px 0 0 0px;
        margin: 0;
    }

    .about-testi-scroll {
        height: 285px;
    }

    .style-two.about-section .testimonial-single-box2 {
        margin-bottom: 0;
        margin-top: 60px;
    }

    .rs-video {
        padding: 30px 0 35px;
    }


    /* Service  Section
    =================*/

    .style-two.service-section .dexon-section-title.padding-lg {
        padding: 0 0 0px;
    }

    .dexon-desc {
        padding-bottom: 50px;
    }

    .services-icon {
        margin-top: -135px;
    }


    /*Testi Section
    =====================*/

    .testi-shape1 {
        left: 4rem;
    }

    .style-two .company-rating {
        display: none;
    }

    /*Call to action
    ================*/

    .call-to-action {
        text-align: center;
    }

    .call-info {
        padding: 50px 0 0;
    }

    /*Footer Section
    =====================*/

    .company-info-desc p {
        font-size: 15px;
    }

    h2.widget-title {
        font-size: 25px;
    }

    .footer-bottom-menu ul li {
        padding-left: 30px;
        font-size: 15px;
    }

    .footer-bottom-content-copy p {
        font-size: 15px;
    }


    /*Inner Page
    ==================*/

    .breatcam-content h1 {
        font-size: 40px;
        line-height: 32px;
    }

    .breatcam-content p {
        width: 71%;
    }

    .contact-thumb img {
        width: 100%;
        margin-bottom: 30px;
    }

    .contact-info {
        padding: 40px 30px 40px;
        text-align: left;
    }

    /*Counter
    ==============*/

    .single-counter-box {
        padding: 35px 18px 35px;
    }

    span.counter-text2 {
        font-size: 13px;
    }

    .counter-icon {
        float: left;
    }

    /*progress
    ==============*/

    .progress-right-content {
        padding-left: 10px;
    }

    .progress-single-box {
        width: 32%;
        bottom: 30%;
        left: 11%;
    }

    /*Service Details
    =====================*/

    .service-details:before {
        width: 100%;
    }

    .service-dtls-all-items {
        padding-left: 0;
    }

    .service-dtls-box {
        background: #fff;
    }

    .service-dtls-items {
        margin-bottom: 30px;
    }

    .service-dtls-cont h3 {
        font-size: 24px;
    }

    h2.accordion-title {
        font-size: 24px;
    }

    .accordion li a {
        font-size: 20px;
    }

    /*Call to Action
    =====================*/

    .call-to-action-box {
        align-items: inherit;
    }

    h2.call-title {
        font-size: 26px;
        padding: 15px 0 6px;
    }

    .call-to-btn.text-right {
        text-align: center !important;
        margin: -90px 0 0px 115px;
    }

    /*Proejct
    ================*/

    .project-detalis-section .project-thumb {
        margin-bottom: 30px;
    }

    .project-info {
        margin-top: 30px;
    }

    /*Shop Section
    ====================*/

    .shop-section:before {
        width: 100%;
        height: 31%;
    }

    .row.products {
        padding-left: 0;
    }

    .upper.widget_search input {
        width: 100%;
    }

    .form_box select {
        width: 37%;
    }

    table.tab-items-table tr td {
        padding-right: 65px;
    }

    .tabs_item img {
        width: 100%;
    }

    .style-three.tab {
        padding: 0px 0px 0px;
    }

    .tabs-bg {
        padding: 60px 30px 30px;
    }

    .shop-dtls-info {
        padding-left: 10px;
    }

    .category-description p {
        font-size: 14px;
    }

    .category-button a {
        padding: 12px 18px;
        font-size: 12px;
    }

    table.category-table tr td {
        padding-right: 18px;
    }

    table.category-table td.table-title {
        font-size: 14px;
    }

    table.category-table td.table-text {
        font-size: 14px;
    }

    .style-three .tabs li a {
        padding: 1px;
        margin-right: 10px;
    }

    .single-products-box:hover .product-thumb-icon a:nth-child(1) {
        left: 110px;
    }

    .single-products-box:hover .product-thumb-icon a:nth-child(2) {
        right: 110px;
    }

    .category-button a i {
        font-size: 13px;
    }

    .category-button a {
        padding: 8px 7px;
        font-size: 12px;
    }

    .quantity .cart-plus-minus {
        width: 140px;
    }

    .quantity .cart-plus-minus > .cart-plus-minus-box {
        width: 140px;
    }

    
    /*Blog Section
    =====================*/

    .blogs-section:before {
        width: 100%;
    }

    .blogs-section h2.blog-title a {
        font-size: 20px;
        font-weight: 500;
    }

    .logo-thumb img {
        width: inherit;
    }

    .row.blogs-pr {
        padding-right: 0;
    }

    .blog-post-comment span.rights-reply {
        top: -91px;
    }

    .contact-form-box3 .form-box input {
        background-color: #fff;
    }

    .contact-form-box3 .form-box textarea {
        background-color: #fff;
    }

    .blogs-section .sidebar-widget {
        margin-top: 30px;
    }

    .pagination-menu {
        padding-bottom: 30px;
    }
    .hero-button a {
        margin-left: 0;
    }
    .choose-button a {
        padding: 24px 30px;
    }

}

/* Large Mobile :600px. */
@media only screen and (min-width: 600px) and (max-width: 767px) {

    .dexon_nav_manu {
        display: none;
    }

    .header-top-section {
        display: none;
    }

    

    /*hero section
    ===================*/

    .hero-section {
        height: 790px;
    }

   .hero-content h1 {
        font-size: 45px;
        font-weight: 600;
    }

    .hero-content h2 {
        font-size: 45px;
        padding: 0px 0 20px 0px;
    }
    .hero-content-text-btn {
        margin-left: 0;
    }
    .hero-content h2:before {
        background: transparent;
    }
    .hero-button a {
        margin-left: 0;
    }
    .hero-content p {
        width: 75%;
    }

    .hero-all-shape {
        display: none;
    }

    /*style two*/

    .hero2.hero-section {
        height: 1250px;
    }

    .hero-thumb {
        margin-top: 30px;
    }

    .hero-content2 h1 {
        font-size: 45px;
        line-height: 40px;
    }

    .hero-thumb img {
        width: 100%;
    }

    .hero-counter-box {
        left: 155px;
    }

    .hero-counter {
        padding-left: 30px;
    }

    .hero-counter h2 {
        font-size: 35px;
    }

    .hero-counter span {
        font-size: 15px;
    }

    .hero-counter.upper {
        padding-left: 50px;
    }

    .hero-counter.upper:before {
        left: 22px;
        top: 16px;
    }



    /*Feature section
    =========================*/

    .dream-shape {
        display: none;
    }

    /*Start Section title
    =========================*/

    .dexon-section-title h1 {
        font-size: 30px;
        line-height: 40px;
    }

    .text-center.dexon-section-title p {
        width: 66%;
    }

    /*Service Section
    =====================*/

    .style-two.service-section .dexon-section-title.padding-lg {
        padding: 0 0 0px;
    }

    .style-two.service-section .dexon-desc p {
        padding: 30px 0 20px;
        width: 70%;
    }

    .services-icon {
        margin-top: -132px;
    }

    .serivce-thumb {
        margin-bottom: 25px;
    }

    /*Start About Section
    =========================*/

    .about-thumb {
        margin-bottom: 40px;
    }

    .row.counter-bg {
        text-align: center;
    }

    .about-thumb2 img {
        width: 100%;
    }

    .people-text p {
        width: 48%;
    }

    .about-section .about-thumb {
        padding: 30px 0 0 0px;
    }

    .about-thumb img {
        width: 100%;
    }

    .rs-video {
        padding: 30px 0 35px;
    }

    /*Testi Section
    =====================*/

    .testi-shape1 {
        left: 0rem;
        top: 70px;
    }

    .testi-shape {
        right: 1rem;
    }

    .about-testi-scroll .testimonial-single-box2 {
        margin-bottom: 0;
        margin-top: -10px;
    }

    /*Call to action
    ==================*/

    .call-info {
        text-align: left;
        margin-top: 40px;
    }

    /*Footer Section
    =====================*/

    h4.widget-title {
        padding: 25px 0 13px;
    }


    .footer-bottom-content-copy {
        text-align: center;
    }

    .footer-bottom-menu.text-right {
        text-align: center !important;
    }

    .footer-bottom-content-copy p {
        margin: 0 0 15px;
    }

    /*Inner page
    ===================*/

    .breatcam-content h1 {
        font-size: 42px;
        line-height: 42px;
    }

    .breatcam-content p {
        width: 77%;
    }

    span.counter-text2 {
        display: block;
    }

    span.counter-text2:before {
        position: inherit;
    }

    .contact-thumb img {
        width: 100%;
        margin-bottom: 30px;
    }

    /*Progress
    =================*/

    .progress-section {
        background-position: center right;
    }

    .progress-right-content {
        padding-left: 0;
    }

    .progress-single-box {
        width: inherit;
        position: inherit;
        margin: 35px 0 20px;
    }

    /*Service Details
    =====================*/

    .service-details:before {
        width: 100%;
    }

    .service-dtls-all-items {
        padding-left: 0;
    }

    .service-dtls-box {
        background: #fff;
        padding: 0 20px 0 0;
        margin-bottom: 30px;
    }

    .service-dtls-items {
        margin-bottom: 30px;
    }

    .service-dtls-cont h3 {
        font-size: 24px;
    }

    /*accordion
    ================*/

    h2.accordion-title {
        font-size: 24px;
    }

    .accordion a:after {
        top: 33px;
    }

    .accordion a:before {
        top: 29px;
    }

    .col-lg-6.col-md-6.faq-pd {
        padding: 0 10px 0 12px;
    }

    /*Call To Action
    ====================*/

    .call-to-action-box {
        align-items: inherit;
    }

    .call-to-thumb {
        margin-right: 22px;
    }

    h2.call-title {
        font-size: 22px;
        font-weight: 500;
    }

    .call-to-btn.text-right {
        margin: -70px 54px 0 0px;
    }

    .call-to-btn a {
        padding: 12px 40px;
        font-size: 14px;
    }

    /*Proejct
    ================*/
    
    .project-detalis-section .project-thumb {
        margin-bottom: 30px;
    }

    .project-info {
        margin-top: 30px;
    }

    .project-dtls-box {
        text-align: center;
    }

    /*Shop Sectio
    ====================*/

    .shop-section:before {
        width: 100%;
        height: 15%;
    }

    .row.products {
        padding-left: 0;
    }

    .upper.widget_search input {
        width: 100%;
    }

    .form_box select {
        width: 74%;
    }

    .form_box {
        padding: 35px 0 25px;
    }

    .single-products-box:hover .product-thumb-icon a:nth-child(1) {
        left: 200px;
    }

    .single-products-box:hover .product-thumb-icon a:nth-child(2) {
        right: 200px;
    }

    .style-three.tab {
        padding: 0px 0px 0px;
    }

    .style-three .tabs_item {
        padding: 0;
    }
    .style-three .tabs {
        margin: 30px 0 0;
    }

    .tabs-bg {
        padding: 50px 25px 30px;
    }

    .style-three.tab {
        margin-right: 0px;
    }

    .tabs_item img {
        width: 100%;
    }

    .appoinment-tab {
        padding: 35px 0 0;
    }

    h2.tab-title {
        font-size: 23px;
    }

    ul.comment-icon-list {
        margin-top: -5px;
    }

    ul.comment-icon-list li {
        letter-spacing: 1px;
        font-size: 12px;
    }

    h4.post-title {
        font-size: 16px;
    }

    .shop-dtls-info {
        padding: 24px 0 0;
    }

    table.tab-items-table td.table-text {
        font-size: 13px;
    }

    table.tab-items-table td.table-title {
        font-size: 14px;
    }

    table.tab-items-table tr {
        padding: 20px 0 0 15px;
    }

    table.tab-items-table tr td {
        padding-right: 10px;
    }

    .single-products-box2 {
        display: inherit;
    }

    .single-products-box2 .products-thumb img {
        width: 100%;
        margin-bottom: 30px;
    }

    /*Blog Section
    =====================*/

    .blogs-section:before {
        width: 100%;
    }

    .blogs-section h2.blog-title a {
        font-size: 20px;
        font-weight: 500;
    }

    .logo-thumb img {
        width: inherit;
    }

    p.blog-desc {
        font-size: 14px;
    }

    .row.blogs-pr {
        padding-right: 0;
    }

    .blog-post-comment span.rights-reply {
        display: none;
    }

    .contact-form-box3 .form-box input {
        background-color: #fff;
    }

    .contact-form-box3 .form-box textarea {
        background-color: #fff;
    }

    .blogs-section .sidebar-widget {
        margin-top: 30px;
    }

    .pagination-menu {
        padding-bottom: 30px;
    }


}

/* small mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 599px) {

    .dexon_nav_manu {
        display: none;
    }

    .header-top-section {
        display: none;
    }


    /*hero section
    ===================*/

    .hero-section {
        height: 700px;
    }

   .hero-content h1 {
        font-size: 35px;
        font-weight: 600;
    }

    .hero-content h2 {
        font-size: 35px;
        padding: 0px 0 20px 0px;
    }

    .hero-content h2:before {
        background: transparent;
    }

    .hero-content p {
        width: 75%;
    }

    .hero-all-shape {
        display: none;
    }

    .dream-shape {
        display: none;
    }

    .hero-button a {
        margin-left: 0;
    }
    /*style two*/

    .hero2.hero-section {
        height: 1250px;
    }

    .hero-thumb {
        margin-top: 30px;
    }

    .hero-content2 h1 {
        font-size: 45px;
        line-height: 40px;
    }

    .hero-thumb img {
        width: 100%;
    }

    .hero-counter-box {
        left: 135px;
    }

    .hero-counter {
        padding-left: 30px;
    }

    .hero-counter h2 {
        font-size: 32px;
    }

    .hero-counter span {
        font-size: 15px;
    }

    .hero-counter.upper {
        padding-left: 30px;
    }

    .hero-counter.upper:before {
        left: 10px;
        top: 16px;
    }


    /*Start Section title
    =========================*/

    .dexon-section-title h1 {
        font-size: 26px;
        line-height: 40px;
    }

    .text-center.dexon-section-title p {
        width: 75%;
    }

    /*Start About Section
    =========================*/


    .about-thumb {
        margin-bottom: 40px;
    }

    .row.counter-bg {
        text-align: center;
    }

    .about-thumb2 img {
        width: 100%;
    }

    .people-text p {
        width: 58%;
    }

    .about-section .about-thumb {
        padding: 30px 0 0 0px;
    }

    .about-thumb img {
        width: 100%;
    }

    .about-section .about-shape {
        left: 72px;
        bottom: 21rem;
    }

    .about-left-thumb img {
        width: 100%;
    }


    /*Service Section
    ==================*/

    .service h1.sections {
        margin-top: 0;
    }

    .service .dexon-section-title h1 {
        line-height: 40px;
    }

    .style-two.service-section .dexon-section-title.padding-lg {
        padding: 0 0 0px;
    }

    .style-two.service-section .dexon-desc p {
        padding: 30px 0 20px;
        width: 70%;
    }

    .services-icon {
        margin-top: -132px;
    }

    .serivce-thumb {
        margin-bottom: 25px;
    }


    /*Testi Section
    =====================*/

    .testi-shape1 {
        left: 15px;
    }

    .testi-shape {
        right: 2rem;
        bottom: 7rem;
    }

    .testimonial-single-box2 .testi-description p {
        font-size: 16px !important;
    }

    .testimonial-single-box2 {
        margin-bottom: -10px;
    }

    /*Call to action
    ================*/

    .call-info {
        text-align: left;
        margin-top: 35px;
    }


    /*Footer Section
    =====================*/

    h4.widget-title {
        padding: 25px 0 13px;
    }


    .footer-bottom-content-copy {
        text-align: center;
    }

    .footer-bottom-menu.text-right {
        text-align: center !important;
    }

    .footer-bottom-content-copy p {
        margin: 0 0 15px;
    }

    .company-work-hour {
        padding: 19px 15px 0 0;
    }

    /*Inner page*/

    .breatcam-content h1 {
        font-size: 35px;
        line-height: 28px;
    }

    .breatcam-content p {
        width: 81%;
    }

    span.counter-text2 {
        display: block;
    }

    span.counter-text2:before {
        position: inherit;
    }

    .rs-video {
        padding: 30px 0 35px;
    }

    .contact-thumb img {
        width: 100%;
        margin-bottom: 30px;
    }

    .contact-info {
        padding: 40px 0px 15px;
    }


    /*Service Details
    =====================*/

    .col-lg-3.col-md-12.pr-0.pl-0.responsive {
        padding: 0 15px;
    }

    .service-details:before {
        width: 100%;
    }

    .service-dtls-all-items {
        padding-left: 0;
    }

    .service-dtls-box {
        background: #fff;
        padding: 0 20px 0 0;
        margin-bottom: 30px;
    }

    .service-dtls-items {
        margin-bottom: 30px;
    }

    .service-dtls-cont h3 {
        font-size: 24px;
    }

    /*accordion
    ================*/

    h2.accordion-title {
        font-size: 24px;
    }

    .accordion a:after {
        top: 33px;
    }

    .accordion a:before {
        top: 29px;
    }

    .accordion li a {
        font-size: 17px;
    }

    .col-lg-6.col-md-6.faq-pd {
        padding: 0 10px 0 12px;
    }

    /*Proejct
    ================*/
    
    .project-detalis-section .project-thumb {
        margin-bottom: 30px;
    }

    .project-info {
        margin-top: 30px;
    }

    .project-dtls-box {
        text-align: center;
    }

    /*Shop Section
    ====================*/

    .shop-section:before {
        width: 100%;
        height: 16%;
    }

    .row.products {
        padding-left: 0;
    }

    .upper.widget_search input {
        width: 100%;
    }

    .form_box select {
        width: 100%;
    }

    .form_box {
        padding: 35px 0 25px;
    }

    .single-products-box:hover .product-thumb-icon a:nth-child(1) {
        left: 170px;
    }

    .single-products-box:hover .product-thumb-icon a:nth-child(2) {
        right: 170px;
    }

    .style-three.tab {
        padding: 0px 0px 0px;
    }

    .style-three .tabs_item {
        padding: 0;
    }
    .style-three .tabs {
        margin: 30px 0 0;
    }

    .tabs-bg {
        padding: 35px 30px 30px;
    }

    .style-three.tab {
        margin-right: 0;
    }

    .tab-boxs {
        padding: 40px 40px 50px;
    }

    .appoinment-tab {
        padding: 35px 0 0;
    }

    .post-content {
        padding: 28px 25px 10px;
    }

    h2.tab-title {
        font-size: 23px;
    }

    ul.comment-icon-list {
        margin-top: -5px;
    }

    ul.comment-icon-list li {
        letter-spacing: 1px;
        font-size: 12px;
    }

    h4.post-title {
        font-size: 16px;
    }

    .shop-dtls-info {
        padding: 24px 0 0;
    }

    table.tab-items-table td.table-text {
        font-size: 13px;
    }

    table.tab-items-table td.table-title {
        font-size: 14px;
    }

    table.tab-items-table tr {
        padding: 20px 0 0 15px;
    }

    table.tab-items-table tr td {
        padding-right: 10px;
    }

    .style-three .tabs li a {
        padding: 4px;
        margin-right: 8px;
    }

    .appoinment-tab li.current a {
        font-size: 14px;
        padding: 9px 18px;
        margin-right: 14px;
    }

    .tabs_item img {
        width: 100%;
    }
    .appoinment-tab .tabs li a {
        font-size: 14px;
        padding: 9px 21px;
    }

    .single-products-box2 {
        display: inherit;
    }

    .single-products-box2 .products-thumb img {
        width: 100%;
        margin-bottom: 30px;
    }

    /*Blog Section
    =====================*/

    .blogs-section:before {
        width: 100%;
    }

    .blogs-section h2.blog-title a {
        font-size: 20px;
        font-weight: 500;
    }

    .logo-thumb img {
        width: inherit;
    }

    p.blog-desc {
        font-size: 14px;
    }

    .row.blogs-pr {
        padding-right: 0;
    }

    .blog-post-comment span.rights-reply {
        display: none;
    }

    .contact-form-box3 .form-box input {
        background-color: #fff;
    }

    .contact-form-box3 .form-box textarea {
        background-color: #fff;
    }

    .blogs-section .sidebar-widget {
        margin-top: 30px;
    }

    img.qte-icon {
        float: inherit;
        margin-bottom: 20px;
    }

    .blog-quote p {
        font-size: 16px;
    }

    .blogs-section .single-blog-dtls-box .blog-content {
        padding: 35px 24px 25px;
    }

    .blog-post-comment .post-comment2 {
        margin-left: 60px;
    }

    p.posts-reply {
        font-size: 14px;
    }

    .pagination-menu {
        padding-bottom: 30px;
    }
    .hero-content-text-btn {
        margin-left: 0;
    }

}

/* small mobile :320px. */
@media only screen and (min-width: 320px) and (max-width: 479px) {

    .dexon_nav_manu {
        display: none;
    }

    .header-top-section {
        display: none;
    }
    .hero-content-text-btn {
        margin-left: 0;
    }
    
/*hero section
    ===================*/

    .hero-section {
        height: 700px;
    }

   .hero-content h1 {
        font-size: 30px;
        font-weight: 500;
        line-height: 30px;
    }

    .hero-content h2 {
        font-size: 30px;
        padding: 0px 0 20px 0px;
    }

    .hero-content h2:before {
        background: transparent;
    }

    .hero-content p {
        width: 100%;
    }
    .hero-button a {
        margin-left: 0;
    }
    .hero-all-shape {
        display: none;
    }
    .hero-content {
        padding-right: 0;
    }
    .dream-shape {
        display: none;
    }

    /*style two*/

    .hero2.hero-section {
        height: 1000px;
    }

    .hero-thumb {
        margin-top: 30px;
        padding-left: 0;
    }

    .hero-content2 h1 {
        font-size: 30px;
        line-height: 25px;
    }

    .hero-thumb img {
        width: 100%;
    }

    .hero-counter-box {
        left: 71px;
        bottom: -7px;
    }

    .hero-counter {
        display: none;
    }

    .hero-counter.upper {
        display: inline-block;
    }

    .hero-counter h2 {
        font-size: 30px;
    }

    .hero-counter span {
        font-size: 15px;
    }

    .hero-counter.upper {
        padding-left: 30px;
    }

    .hero-counter.upper:before {
        left: 10px;
        top: 16px;
    }



    /*Start Section title
    =========================*/

    .dexon-section-title h1 {
        font-size: 22px;
        line-height: 26px;
    }

    .text-center.dexon-section-title p {
        width: 88%;
    }

    .text-center.dexon-section-title p {
        font-size: 16px !important;
    }

    /*Start About Section
    =========================*/

    .about-thumb2 {
        padding-left: 0;
        padding-top: 25px;
    }
    .about-thumb {
        margin-bottom: 40px;
    }

    .row.counter-bg {
        text-align: center;
    }

    .about-thumb2 img {
        width: 100%;
    }

    .people-text p {
        width: 100%;
    }

    .about-section .about-thumb {
        padding: 30px 0 0 0px;
    }

    .about-thumb img {
        width: 100%;
    }

    .about-section .about-shape {
        left: 72px;
        bottom: 14rem;
    }

    .about-left-thumb img {
        width: 100%;
    }

    /*Service Section
    ===================*/

    .service h1.sections {
        margin-top: 0;
    
    }

    .service .dexon-section-title h1 {
        line-height: 30px;
    }

    .single-service-box {
        padding: 35px 30px 30px;
    }

    h3.service-title {
        font-size: 22px;
    }

    .style-two.service-section .dexon-section-title.padding-lg {
        padding: 0 0 0px;
    }

    .style-two.service-section .dexon-desc p {
        padding: 30px 0 20px;
        width: 70%;
    }

    .services-icon {
        margin-top: -132px;
    }

    .serivce-thumb {
        margin-bottom: 25px;
    }

    .serivce-thumb img {
        width: 100%;
    }

    .services-icon a {
        font-size: 40px;
        height: 60px;
        width: 60px;
        line-height: 60px;
    }

    .service-icon {
        float: inherit;
    }

    .service-icon img {
        width: inherit;
    }

    .single-service-box2 h2.service-title {
        font-size: 21px;
        padding: 5px 0 22px;
        line-height: inherit;
    }

    .single-service-box2 p.service-desc {
        width: 100%;
    }

    .right-arrow {
        display: none;
    }

    /*feature section*/

    .style-two .feature-single-box {
        padding: 40px 32px 28px;
    }

    .feature-content h2 {
        font-size: 20px;
    }

    /*proejct */

    .project-section .dexon-section-title h1 {
        font-size: 19px;
        line-height: 25px;
    }

    /*Testi Section
    =====================*/

    .testi-shape1 {
        left: 15px;
    }

    .testi-shape {
        right: 2rem;
        bottom: 7rem;
    }

    .company-rating ul li {
        font-size: 13px;
        letter-spacing: 4px;
    }

    .testi-description p {
        font-size: 15px;
    }

    .style-two .company-rating ul li {
        display: none;
    }

    .testimonial-single-box {
        padding: 40px 22px 30px;
    }

    .style-two .testi-description p {
        font-size: 16px !important;
    }

    .testimonial-single-box2 .testi-description p {
        line-height: 27px;
    }

    /*Call to action
    ================*/

    .call-info {
        text-align: left;
        margin-top: 35px;
    }

    .call-number h1 {
        font-size: 25px;
    }

    .choose-button a {
        padding: 20px 20px;
        font-size: 18px;
    }

    /*Footer Section
    =====================*/

    h4.widget-title {
        padding: 25px 0 13px;
    }

    .recent-post-text a {
        font-size: 14px;
    }

    .footer-bottom-content-copy {
        text-align: center;
    }

    .footer-bottom-menu.text-right {
        text-align: center !important;
    }

    .footer-bottom-content-copy p {
        margin: 0 0 15px;
        font-size: 15px;
    }

    .company-work-hour {
        padding: 19px 15px 0 0;
    }

    .footer-bottom-menu ul li {
        padding-left: 20px;
    }

    .footer-bottom-menu ul li a {
        font-size: 15px;
    }

    /*Inner page*/

    .breatcam-content h1 {
        font-size: 22px;
        line-height: 18px;
    }

    .breatcam-content p {
        width: 100%;
    }

    .rs-video {
        padding: 30px 0 35px;
    }

    .style-two .inner-shape2 {
        top: -42px;
    }

    /*Counter
    ====================*/

    .counter-title h1 {
        font-size: 35px;
    }

    .counter-icon {
        padding-right: 12px;
    }

    span.counter-text2 {
        display: block;
        font-size: 13px;
    }

    span.counter-text2:before {
        position: inherit;
    }

    .contact-thumb img {
        width: 100%;
        margin-bottom: 30px;
    }

    .contact-info {
        padding: 40px 0px 15px;
    }

    /*Progress
    =================*/

    .progress-section {
        background-position: center right;
    }

    .progress-right-content {
        padding-left: 0;
    }

    .progress-single-box {
        width: inherit;
        position: inherit;
        margin: 35px 0 20px;
    }

    .rs-video {
        left: -110px;
    }

    /*Service Details
    =====================*/

    .col-lg-3.col-md-12.pr-0.pl-0.responsive {
        padding: 0 15px;
    }

    .service-details:before {
        width: 100%;
    }

    .service-dtls-all-items {
        padding-left: 0;
    }

    .service-dtls-box {
        background: #fff;
        padding: 0 20px 0 0;
        margin-bottom: 30px;
    }

    .service-dtls-items {
        margin-bottom: 30px;
    }

    .service-dtls-cont h3 {
        font-size: 21px;
        font-weight: 600;
    }

    ul.sidebar-menu2 li {
        font-size: 15px;
    }

    img.sidbr-icn {
        padding-right: 10px;
    }

    h2.service-title {
        font-size: 22px;
    }

    .sevice-dtls-content h4 {
        font-size: 16px;
    }

    .sevice-dtls-content {
        padding-left: 15px;
    }

    .sevice-dtls-content p {
        font-size: 14px;
    }

    /*accordion
    ================*/

    h2.accordion-title {
        font-size: 19px;
    }

    .accordion a:after {
        top: 33px;
    }

    .accordion a:before {
        top: 29px;
    }

    .accordion li a {
        font-size: 17px;
        padding: 20px 20px 20px 20px;
    }

    .accordion li p {
        font-size: 14px;
    }

    .faq-thumb img {
        width: 100%;
    }

    .faq-thumb {
        margin: 0;
    }

    .col-lg-6.col-md-6.faq-pd {
        padding: 0 10px 0 12px;
    }

    /*Call to Action
    =====================*/

    .call-to-action-box {
        display: inherit;
    }

    .call-to-btn.text-right {
        text-align: left !important;
    }

    span.call-desc {
        padding: 10px 0 25px;
        font-size: 14px;
    }

    h2.call-title {
        font-size: 20px;
        padding: 20px 0 0;   
    }

    /*Project
    =================*/

    h3.case-study-title a {
        padding: 14px 45px 14px 25px;
        font-size: 16px;
    }

    h6.case-study-title {
        font-size: 14px;
    }

    /*Proejct
    ================*/
    
    .project-detalis-section .project-thumb {
        margin-bottom: 30px;
    }

    .project-info {
        margin-top: 30px;
        padding: 35px 34px 30px;
    }

    .project-dtls-box {
        text-align: center;
    }

    .pagination1 a {
        font-size: 16px;
        width: 30px;
        height: 30px;
        line-height: 30px;
    }

    .pagination1 span {
        font-size: 13px;
    }

    /*Shop Section
    ====================*/

    .shop-section:before {
        width: 100%;
        height: 20%;
    }

    .row.products {
        padding-left: 0;
    }

    .upper.widget_search input {
        width: 100%;
    }

    .form_box select {
        width: 100%;
    }

    .form_box {
        padding: 35px 0 25px;
    }

    .single-products-box:hover .product-thumb-icon a:nth-child(1) {
        left: 98px;
    }

    .single-products-box:hover .product-thumb-icon a:nth-child(2) {
        right: 98px;
    }

     .style-three.tab {
        padding: 0px 0px 0px;
    }

    .style-three .tabs_item {
        padding: 0;
    }
    .style-three .tabs {
        margin: 30px 0 0;
    }

    .shop-dtls-info {
        padding: 24px 0 0;
    }

    .style-three.tab {
        padding: 0px 0px 0px;
    }

    .style-three .tabs_item {
        padding: 0;
    }
    .style-three .tabs {
        margin: 30px 0 0;
    }

    .tabs-bg {
        padding: 50px 25px 30px;
    }

    .style-three.tab {
        margin-right: 0px;
    }

    .tabs_item img {
        width: 100%;
    }

    .category-title h2 {
        font-size: 22px;
    }

    .appoinment-tab {
        padding: 35px 0 0;
    }

    .tab-boxs {
        padding: 40px 30px 50px;
    }

    .appoinment-tab .tabs_item img {
        width: inherit;
    }

    .appoinment-tab .tabs li a {
        font-size: 15px;
        padding: 7px 24px;
    }

    .post-content {
        padding: 28px 25px 10px;
    }

    h2.tab-title {
        font-size: 21px;
    }

    ul.comment-icon-list {
        display: none;
    }

    h4.post-title {
        font-size: 16px;
    }

    .category-count-button {
        display: inline-block;
    }

    .category-button {
        padding-left: 0;
        margin-top: 25px;
    }

    .category-button a {
        font-size: 15px;
    }

    .single-products-box2 {
        display: inherit;
    }

    .single-products-box2 .products-thumb img {
        width: 100%;
        margin-bottom: 30px;
    }

    /*Blog Section
    =====================*/

    .blogs-section:before {
        width: 100%;
    }

    .blogs-section h2.blog-title a {
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
    }

    .logo-thumb img {
        width: inherit;
    }

    p.blog-desc {
        font-size: 14px;
    }

    .row.blogs-pr {
        padding-right: 0;
    }

    .blogs-section .blog-content {
        padding: 35px 25px 18px;
    }

    ul.blog-author li {
        margin-right: 22px;
        font-size: 13px;
    }

    ul.blog-author li span:before {
        right: -13px;
    }

    .style-two.blogs-section h2.blog-title a {
        font-size: 18px;
        font-weight: 600;
    }

    .blog-post-comment span.rights-reply {
        display: none;
    }

    .contact-form-box3 .form-box input {
        background-color: #fff;
    }

    .contact-form-box3 .form-box textarea {
        background-color: #fff;
    }

    .blogs-section .sidebar-widget {
        margin-top: 30px;
    }

    img.qte-icon {
        float: inherit;
        margin-bottom: 20px;
    }

    .blog-quote p {
        font-size: 16px;
    }

    .blogs-section .single-blog-dtls-box .blog-content {
        padding: 35px 24px 25px;
    }

    .post-comment-thumb {
        float: inherit;
        margin-bottom: 20px;
    }

    .blog-post-comment .post-comment2 {
        margin-left: 0px;
    }

    p.posts-reply {
        font-size: 14px;
    }

    h3.blog-comment-title {
        font-size: 22px;
        font-weight: 600;
    }

    .prc-thumb {
        right: 8rem;
        bottom: 21rem;
    }

    .pagination-menu {
        padding-bottom: 30px;
    }

    .rpost-content h4 a {
        font-size: 16px;
        font-weight: 400;
    }

    .blogs-section .widget-sidebar-box {
        padding: 35px 25px 50px;
    }

    .blogs-section h2.blog-title2 a {
        font-size: 25px;
        font-weight: 500;
    }

    .blog-post-comment2 {
        padding: 38px 30px 55px;
    }

    .blog-post-comment2 .post-comment {
        padding: 30px 25px 0;
    }
    .widget-title2 h3 {
        font-size: 32px;
    }
    .service-page-title h1 {
        font-size: 30px;
        line-height: 35px;
    }

}

